<template>
	<div class="calendar-events">
		<b-container class="mt-5">
			<b-row class="mb-4" align-v="center">
				<b-col cols="12">
					<h1 class="display-4  super-strong">Vinculación : Eventos</h1>
					<hr class="line" />
				</b-col>
			</b-row>

			<b-row class="mb-5">
				<b-col md="12">
					<h2 class="">
						<strong>{{ event.eventName }}</strong>
					</h2>
				</b-col>
				<b-col md="8">
					<b-card-group deck>
						<b-card :header="`Título del evento: ${event.eventName}`">
							<b-list-group>
								<b-list-group-item>
									Sede:
									<strong>{{ event.location }}</strong>
								</b-list-group-item>
								<b-list-group-item>
									Modalidad:
									<strong>{{ event.modalidad }}</strong>
								</b-list-group-item>
								<b-list-group-item v-if="event.locationMap">
									Mapa:
									<strong>
										<a :href="event.locationMap" target="_blank">
											{{ event.locationMap }}
										</a>
									</strong>
								</b-list-group-item>
								<b-list-group-item v-if="event.eventDate">
									Fecha:
									<strong>
										{{ event.eventDate }}
										{{ event.eventSchedule }}
									</strong>
								</b-list-group-item>

								<!-- <b-list-group-item>
									<p class="card-text mt-2">
										<small>Detalles:</small><br />
										<strong>{{ event.descripcion }}</strong>
									</p>
								</b-list-group-item> -->

								<b-list-group-item>
									<small>Contacto</small><br />
									<span v-if="event.fullName">
										Organizador:
										<strong>{{ event.fullName }}</strong>
									</span>
									<br />
									<span v-if="event.email">
										Email: <strong>{{ event.personalEmail }}</strong>
									</span>
									<br />
									<span v-if="event.telefono">
										Tel: <strong>{{ event.personalPhone }}</strong>
									</span>
								</b-list-group-item>
							</b-list-group>
						</b-card>
					</b-card-group>
				</b-col>
				<b-col md="4" class="">
					<b-img
						v-for="(picture, i) in event.eventPoster"
						:key="i"
						:src="picture.content"
						fluid
						:alt="event.eventName"
						class="shadow-3"
					></b-img>
				</b-col>
			</b-row>
		</b-container>
		<!-- <pre>{{ event }}</pre> -->
	</div>
</template>

<script>
	import wsExternal from "@/services/external";
	import { event } from "vue-gtag";

	export default {
		name: "EventoShow",
		components: {},

		props: {
			cfgTitle: String,
		},

		data() {
			return {
				event: {
					answer: {
						cartel_evento: {},
					},
				},
				path: {
					base_url: process.env.VUE_APP_BASE_URL,
					endpoint: process.env.VUE_APP_ENDPOINT,
					storage_files: process.env.VUE_APP_STORAGE_FILES,
				},
			};
		},

		mounted() {
			document.title = "Agenda | Instituto de los Mexicanos en el Exterior";
			this.loadEvent(this.$route.params.uuid);
			//this.$route
		},

		methods: {
			loadEvent(uuid) {
				wsExternal.getEvent(uuid).then(
					(response) => {
						this.event = response.data.data[0];
					},
					(error) => {
						this.event =
							(error.response && error.response.data) ||
							error.message ||
							error.toString();
					}
				);
			},
		},
	};
</script>
